import React, {useState} from "react";
import localStyles from "./styles.module.scss";
import {CoreService} from "../../../services/core.service";
import useToastifyHook from "../../../hooks/useToastifyHook";
import useViewGlobalBackground from "../../../hooks/useViewGlobalBackground";
import uuid from "react-uuid";
import Button from "../../../components/Button";
import PhotoUploader from "../../../components/PhotoUploader";
import ProgressSpinner from "../../../components/ProgressSpinner";
import translate from "../../../utils/translate";
import useCreatePhotoReport from "./useCreatePhotoReport";
import InputText from "../../../components/Inputs/InputText";
import ScannerForm from "../../../components/ScannerForm";

interface ParcelInterface {
    customer_number: string;
    tracknumber: string;
    doc_number: string;
    doc_date: string;
    package_id: string;
    urls: {
        url: string,
        file_name: string,
    }[]
}

interface ParcelResponseInterface {
    response: ParcelInterface[]
}

interface Props {
    close?: () => void;
}

const CreatePhotoReport = ({close}: Props) => {
    const coreService = new CoreService();

    // const {data, refetch: refetchLinks} = useGetLinksQuery(null)

    const toast = useToastifyHook();
    const showBackGround = useViewGlobalBackground()

    const [keyImageComponent, setKeyImageComponent] = useState<string>(uuid())
    const [loading, setLoading] = useState<boolean>(false)
    const [showScanner, setShowScanner] = useState<boolean>(false)

    const {
        formik,
        handleNumber,
        order,
        photosFormOrder,
        setPhotosFromServer,
        setPhotoUploadForm,
        photoUploadForm
    } = useCreatePhotoReport({close, setLoading});

    const readerBarCode = (barcode: string) => {
        handleNumber(barcode)
        setShowScanner(!showScanner)
    }

    // console.log(formik.values, order, photosFormOrder, photoUploadForm)

    return (
        <div className={localStyles.container}>
            <div className={`${localStyles.card} custom-scroll`}>
                <form onSubmit={formik.handleSubmit} noValidate className={localStyles.form}>
                    <div className={localStyles.trackNumberSection}>
                        <InputText id={'tracknumber'}
                                   labelText={'Code'}
                                   value={formik.values.number}
                                   handleChange={(value: string) => {
                                       // setFieldValue('tracknumber', value)
                                       // handleInputTrackNumber(value)
                                   }}
                                   handleBlur={handleNumber}
                        />
                        <Button id={'open_scanner'}
                                classes={localStyles.scannerButton}
                                size={'middle'}
                                background={'bg-primary'}
                                text={translate('scanner')}
                                click={() => setShowScanner(true)}
                        />
                    </div>
                    <div className={localStyles.inputBlock}>
                        <div>
                            <PhotoUploader
                                key={keyImageComponent}
                                additionalImages={photosFormOrder.map((image) => ({
                                    id: image.id,
                                    url: image.bb_link_to_file,
                                    name: image.origin_name
                                }))}
                                showScanner={showScanner}
                                handleImages={(images) => {
                                    setPhotoUploadForm(images.slice(0, 15));
                                }}
                                handleDeleteImage={(id) => {
                                    setPhotoUploadForm(photoUploadForm.filter(value => value.id === id));
                                    setPhotosFromServer(photosFormOrder.filter(value => value.id.toString() !== id))
                                }}
                                // className={`${touched.images && errors.images ? 'is-invalid' : ''}`}
                            />
                            {/*<InputErrorBlock*/}
                            {/*    errorMessage={touched.images ? errors.images?.toString() : ''}/>*/}
                        </div>
                    </div>
                    <div>
                        <Button id={'send'}
                                size={'large'}
                                background={'bg-primary'}
                                text={loading ? '' : translate('send')}
                                uppercase
                                containerClass={'position-relative'}
                                type={'submit'}
                                disabled={loading}
                                appendBefore={(
                                    <div data-if={loading} className={localStyles.loader}>
                                        <ProgressSpinner strokewidth={3} animationduration={3}/>
                                    </div>
                                )}
                        />
                    </div>
                    <div>
                        <Button id={'reset'}
                                size={'large'}
                                background={'bg-danger'}
                                text={translate('cancel')}
                                uppercase
                                click={() => {
                                    // clearForm()
                                    close()
                                }}
                        />
                    </div>
                </form>
            </div>
            {
                showScanner &&
                <div className={localStyles.scannerModal2}>
                    <ScannerForm readerBarCode={readerBarCode} close={() => setShowScanner(false)}/>
                </div>
            }
        </div>
    );
}

export default CreatePhotoReport;
