import React, {BaseSyntheticEvent, useCallback, useEffect, useMemo, useState} from "react";
import localStyles from "./styles.module.scss";
import Card from "../../../../components/Card";
import StatusListComponent from "../../../../components/StatusListComponent";
import icons from "../../../../utils/icons";
import {copy} from "../../../../utils/methods";
import Button from "../../../../components/Button";
import {useGetLinksQuery} from "../../../../storage/api";
import axios from "../../../../utils/axios";
import axiosOrigin from "axios";
import * as Sentry from "@sentry/react";
import translate from "../../../../utils/translate";
import {use} from "i18next";
import {useMediaQuery} from "react-responsive";

interface Props {
    id: number;
    date: string;
    link: string;
    docNumber: string;
    user: {
        username: string
    };
    files: {
        id: number;
        origin_name: string;
        bb_link_to_file: string;
        temporary_link_to_file: string;
    }[];
}

const FilesListItem = ({id, docNumber, user, date, link, files}: Props) => {

    // const {data, refetch: refetchLinks} = useGetLinksQuery(null)

    // const [linksFile, setLinksFile] = useState<any[]>([])

    const isBigScreen = useMediaQuery({ query: '(min-width: 768px)' })
    const isPhoneScreen = useMediaQuery({ query: '(max-width: 640px)' })

    const formattedDate: string = useMemo(() => {
        const dateOfCreation = new Date(date);
        if (isNaN(dateOfCreation?.getTime())) {
            return;
        }
        const year = dateOfCreation.getFullYear();
        const month = String(dateOfCreation.getMonth() + 1).padStart(2, '0');
        const day = String(dateOfCreation.getDate()).padStart(2, '0');
        const hours = String(dateOfCreation.getHours()).padStart(2, '0');
        const minutes = String(dateOfCreation.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}`;
    }, [date]);

    return (<Card className={localStyles.container} onClick={() => {
        window.open(link, '_blank')
    }}>
        <div className={'d-flex justify-content-between'} style={{
            gridColumn: isPhoneScreen ? '1/3' : undefined
        }}>
            <span><strong>#{id}</strong> | {user.username}</span>
            {isPhoneScreen && <strong onClick={event => event.stopPropagation()}>{docNumber}</strong>}
        </div>
        <div className={localStyles.linkContainer}>

            <div className={'d-flex align-items-center'} style={{
                gap: 15
            }}>
                <Button
                    click={(event: BaseSyntheticEvent) => copy(link, null, event)}
                    id={'copy-link'}
                    size={'middle'}
                    background={'bg-primary'}
                    type={'button'}
                    text={translate('copy')}
                    classes={localStyles.copyButton}
                    appendBefore={<img className={localStyles.icon}
                                       src={icons.copy_all}
                                       alt="copyImg"
                    />}
                />
                {isBigScreen && <strong onClick={event => event.stopPropagation()}>{docNumber}</strong>}
            </div>
            <div className={'d-flex'} style={{gap: 10}}>
                {files.slice(0, 3).map(file =>
                    <img loading='lazy' key={file?.id} width={50} height={50} src={file?.bb_link_to_file ?? file?.temporary_link_to_file} alt={file?.origin_name}/>
                )}
            </div>
        </div>
        <p className={localStyles.date} onClick={event => event.stopPropagation()}>{formattedDate}</p>
    </Card>)
}

export default FilesListItem;
