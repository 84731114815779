import {AxiosResponse} from 'axios';
import axios from '../utils/axios';
import axiosOrigin from 'axios'
import environments from '../utils/environments';
import {LoginDataForm} from "../pages/Login";

export class AuthService {
    private readonly apiUrl: string;

    constructor() {
        this.apiUrl = environments.apiUrl;
    }

    getToken(dataForm: LoginDataForm): Promise<AxiosResponse> {

        return axios.post(`${this.apiUrl}/api/v1/auth/login`, dataForm, {
            headers: {
                "Content-Type": "application/json",
            }
        });
    }

    isAuthorized(): Promise<AxiosResponse> {
        return axiosOrigin.create({withCredentials: true}).get(`${this.apiUrl}/api/v1/auth/isAuthorized`);
    }

    logout(): Promise<AxiosResponse> {
        return axios.get(`${this.apiUrl}/api/v1/auth/logout`);
    }
}
