import React, {useState} from 'react';
import localStyles from "../PhotoUploader/styles.module.scss";
import icons from "../../utils/icons";
import {ImageInterface, instanceOfSimpleImageInterface, SimpleImageInterface} from "../PhotoUploader";
import Modal from "../Modal";

interface Interface {
    id: string;
    src: string;
    name: string;
    removeImage?: (id: string|number) => void;
}

const ImageUploadForm = ({id, src, name, removeImage}: Interface) => {

    const [showModal, setShowModal] = useState<boolean>(false)

    const viewFullscreenImage = () => {
        setShowModal(true);
    }

    return (
        <li className={localStyles.listItem}
            key={id}
        >
            <div className={localStyles.bgSmallImage}
                 onClick={() => {
                     viewFullscreenImage()
                 }}
            />
            <img src={src}
                 alt={name}
                 className={localStyles.uploadedImage}
                 width={118}
                 height={102}
            />
            <div className={localStyles.removeImageButton}
                 onClick={() => {
                     removeImage && removeImage(id);
                 }}
            >
                <img src={icons.close_small}
                     alt="Remove selected image"
                     width={25}
                     height={25}
                />
            </div>
            <p>{name}</p>
            <Modal show={showModal}
                   width={560}
                   element={<div>
                       {<img
                           src={src ?? ''}
                           alt={name ?? ''}
                           className={localStyles.uploadedImage}
                           style={{
                               width: '100%',
                               maxHeight: '100%'
                           }}
                       />
                       }
                   </div>}
                   closeOut
                   handleClose={() => {
                       setShowModal(false);
                   }}
            />
        </li>
    );
};

export default ImageUploadForm;
