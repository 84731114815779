import React from "react";
import localStyles from "./styles.module.scss";
import Header from "../../components/Header";
import FilesList from "./FilesList";

const Dashboard = () => {
    return (
        <div className={localStyles.container}>
            <Header />
            <FilesList />
        </div>
    )
}

export default <Dashboard />;
