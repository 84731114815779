import React, {BaseSyntheticEvent, useEffect, useRef, useState} from "react";
import localStyles from "./styles.module.scss";
import icons from "../../utils/icons";
import uuid from "react-uuid";
import Modal from "../Modal";
import translate from "../../utils/translate";
import ProgressSpinner from "../ProgressSpinner";
import ImageUploadForm from "../ImageUploadForm";

const IMAGE_MIME_TYPE: RegExp = /^image\//;

interface Props {
    handleImages?: (images: any[]) => void,
    handleDeleteImage?: (id: string|number) => void,
    className?: string,
    limitSize?: number,
    limitCount?: number,
    showScanner?: boolean;
    additionalImages?: SimpleImageInterface[];
}

export interface ImageInterface {
    base64: string | ArrayBuffer;
    name: string;
    id: string;
    origin: File;
}

export interface SimpleImageInterface {
    id: string|number;
    name: string;
    url: string;
}

const MAX_WIDTH = 3840;
const MAX_HEIGHT = 2160;

const PhotoUploader: React.FC<Props> = ({
                                            handleImages,
                                            handleDeleteImage,
                                            className,
                                            limitSize = 0,
                                            additionalImages,
                                            showScanner,
                                        }: Props) => {

    const filePicker = useRef(null);
    const dropContainer = useRef(null);

    const [images, setImages] = useState([]);
    const [loadingAddImage, setLoadingAddImage] = useState<boolean>(false)

    const handlePick = () => {
        filePicker.current.click();
    }

    const filesHandler = (files: File[]) => {
        const sizeLimitBytes = limitSize * 1000000;
        setLoadingAddImage(true)
        const length = files.length

        Array.from(files)
            .filter((file: File) => !limitSize ? true : file.size <= sizeLimitBytes)
            .map((file: File, index: number) => {
                const blobURL = URL.createObjectURL(file);
                const img = new Image();
                img.src = blobURL;

                img.onload = function () {
                    const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
                    const canvas = document.createElement("canvas");
                    canvas.width = newWidth;
                    canvas.height = newHeight;
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0, newWidth, newHeight);
                    canvas.toBlob(
                        (blob) => {
                            setImages((prevState) => [...prevState, {
                                id: uuid(),
                                name: file.name,
                                type: file.type,
                                blob,
                                base64: canvas.toDataURL("image/webp")
                            }])
                            if ((length - 1) === index)
                                setLoadingAddImage(false);
                        },
                        "image/webp",
                        0.9
                    );
                };
            })
    }

    useEffect(() => {
        handleImages && handleImages(images)
    }, [images]);

    const handleChange = (event: BaseSyntheticEvent) => {
        filesHandler(event.target.files)
    };

    const highlightOn = (event: BaseSyntheticEvent) => {
        event.stopPropagation();
        event.preventDefault();
        dropContainer.current.classList.add(localStyles.highlight)
    }

    const highlightOff = (event: BaseSyntheticEvent) => {
        event.stopPropagation();
        event.preventDefault();
        dropContainer.current.classList.remove(localStyles.highlight)
    }

    const dropHandle = (event: React.DragEvent<HTMLDivElement>) => {
        highlightOff(event);
        let files: File[] = Array.from(event.dataTransfer.files);
        files = files.filter((file) => {
            return IMAGE_MIME_TYPE.test(file.type);
        });
        if (files.length) {
            filesHandler(files)
        }
    }

    const removeImage = (id: string|number) => {
        setImages((prevState) => prevState.filter((value) => value.id !== id));
        handleDeleteImage && handleDeleteImage(id)
    }

    function calculateSize(img: HTMLImageElement, maxWidth: number, maxHeight: number) {
        let width = img.width;
        let height = img.height;

        // calculate the width and height, constraining the proportions
        if (width > height) {
            if (width > maxWidth) {
                height = Math.round((height * maxWidth) / width);
                width = maxWidth;
            }
        } else {
            if (height > maxHeight) {
                width = Math.round((width * maxHeight) / height);
                height = maxHeight;
            }
        }
        return [width, height];
    }

    return (
        <div className={`${localStyles.container} ${className}`}>
            <div style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                zIndex: 1,
                visibility: loadingAddImage ? 'visible' : 'hidden'
            }}>
                <ProgressSpinner strokewidth={2} animationduration={10}/>
            </div>
            <div className={localStyles.inputFilesContainer}
                 ref={dropContainer}
            >
                <input className={localStyles.hidden}
                       type="file"
                       accept=".png,.jpg,.jpeg,.gif,.web"
                       multiple={true}
                       onChange={handleChange}
                       ref={filePicker}
                />

                <p className={`${localStyles.hideOnDesktop} ${localStyles.inputFilesLabel}`}>
                    <span
                        className={localStyles.inputFilesLabelPrimaryText}>{translate('select_a_file')}</span>
                </p>

                <p className={`${localStyles.hideOnMobile} ${localStyles.inputFilesLabel} ${localStyles.inputFilesLabelDesktop}`}>
                        <span className={localStyles.inputFilesLabelPrimaryText}>
                            {translate('drag_the_file')}
                        </span>
                    or
                </p>
                <button type="button" className={`${localStyles.hideOnMobile} ${localStyles.inputFilesButton}`}>
                    <img src={icons.paperclip} alt="Upload files"/>
                    {translate('select_a_file')}
                </button>


                <div className={localStyles.dropArea}
                     onDragEnter={highlightOn}
                     onDragOver={highlightOn}

                     onDragLeave={highlightOff}
                     onDrop={dropHandle}

                     onClick={handlePick}
                ></div>
            </div>
            {
                <ul className={localStyles.viewFilesList} style={{
                    display: showScanner ? 'none' : undefined
                }}>
                    {additionalImages && additionalImages.map((image, index) => {
                        return (<ImageUploadForm key={index} id={image.id.toString()} name={image.name} src={image.url} removeImage={removeImage}/>);
                    })}
                    {images.map((image, index) => {
                        return (<ImageUploadForm key={index} id={image.id} name={image.name} src={image.base64} removeImage={removeImage}/>);
                    })}
                </ul>
            }

        </div>
    )
}

export default PhotoUploader;

export function instanceOfSimpleImageInterface(object: any): object is SimpleImageInterface {
    return 'name' in object && 'url' in object;
}
