import React, {useEffect, useState} from "react";
import localStyles from "./styles.module.scss";
import FilesListItem from "./FilesListItem";
import {useGetLinksQuery} from "../../../storage/api";
import {socket} from "../../../utils/socket";

const FilesList = () => {

    const usersAdmin = ['taras', '001']

    const {data: dataLinks, refetch: refetchLinks,} = useGetLinksQuery();

    const [list, setList] = useState([])

    const [isConnected, setIsConnected] = useState(socket.connected);
    const [liveScans, setLiveScans] = useState([]);

    useEffect(() => {
        function onConnect() {
            setIsConnected(true);
        }

        function onDisconnect() {
            setIsConnected(false);
        }

        function onUpdateLinkEvent(value: any) {
            setList(list.map(item => item.id === value?.id ? value : item))
        }

        function onAddNewLinkEvent(value: any) {
            setList([value, ...list])
        }

        const username = localStorage.getItem('username')
        const userid = localStorage.getItem('userid')

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        if (usersAdmin.includes(username)) {
            socket.on('update_link', onUpdateLinkEvent);
            socket.on('add_new_link', onAddNewLinkEvent);
        } else {
            socket.on('update_link:' + userid, onUpdateLinkEvent);
            socket.on('add_new_link:' + userid, onAddNewLinkEvent);
        }

        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            if (usersAdmin.includes(username)) {
                socket.off('update_link', onUpdateLinkEvent);
                socket.off('add_new_link', onAddNewLinkEvent);
            } else {
                socket.off('update_link:' + userid, onUpdateLinkEvent);
                socket.off('add_new_link:' + userid, onAddNewLinkEvent);
            }
        };
    }, [list]);

    useEffect(() => {
        setList(dataLinks?.list ?? [])
    }, [dataLinks?.list]);

    return (<div className={`container ${localStyles.container}`}>
        {list.map((item, i) => (
            <div key={item.id}>
                <FilesListItem id={item.id}
                               docNumber={item.doc_number}
                               date={item.created_at}
                               link={item.url}
                               user={item?.user}
                               files={item.files}/>
            </div>
        ))}
    </div>)
}

export default FilesList;
