import {useFormik} from "formik";
import {useEffect, useState} from "react";
import {CoreService} from "../../../services/core.service";
import {ApiOrderInterface, ApiPhotoOrderInterface} from "../../../interfaces/entity";

interface Interface {
    close: () => void;
    setLoading: (value: boolean) => void;
}

const useCreatePhotoReport = ({close, setLoading}: Interface) => {

    const coreService = new CoreService()

    const [order, setOrder] = useState<ApiOrderInterface>(null)
    const [photosFormOrder, setPhotosFromServer] = useState<ApiPhotoOrderInterface[]>([])
    const [photoUploadForm, setPhotoUploadForm] = useState<{ id: string, name: string, blob: Blob }[]>([])

    const formik = useFormik({
        initialValues: {
            number: '',
            selectedOrder: null,

        },
        onSubmit: (values, formikHelpers) => {
            console.log({
                order, photosFormOrder, photoUploadForm
            })

            const formData = new FormData();
            formData.append('doc_number', order.doc_number)

            for (let i = 0; i < photoUploadForm.length; i++) {
                const blob = photoUploadForm[i]?.blob;
                const name = photoUploadForm[i].name;
                const file = new File([blob], name);
                formData.append(`files[${i}][type]`, 'new');
                formData.append(`files[${i}][file]`, file);
            }

            for (let i = photoUploadForm.length; i < (photosFormOrder.length + photoUploadForm.length); i++) {
                const photo = photosFormOrder[i - photoUploadForm.length]
                formData.append(`files[${i}][type]`, 'old');
                formData.append(`files[${i}][file_id]`, photo.id.toString());
            }
            setLoading(true)
            coreService.uploadFiles(formData)
                .then(() => {
                    close()
                })
                .catch()
                .finally(() => {
                    setLoading(false)
                })

            // console.log(formData.get('files'))
        }
    })

    useEffect(() => {
        if (order && order.urls.length) {
            setLoading(true)
            coreService.getPhotosByOrder(order.urls)
                .then(({data}) => {
                    setPhotosFromServer(data);
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [order]);

    const handleNumber = (codeNumber: string) => {
        formik.setFieldValue('number', codeNumber)

        if (codeNumber) {
            coreService.getOrderList(codeNumber)
                .then(({data}) => {
                    if (data.list && Array.isArray(data.list) && data.list.length) {
                        setOrder(data.list[0]);
                    }
                })
        }
    }

    return {
        formik,
        handleNumber,
        order,
        photosFormOrder,
        setPhotoUploadForm,
        photoUploadForm,
        setPhotosFromServer,
    }
}

export default useCreatePhotoReport;
