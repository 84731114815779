import React, {useEffect, useState} from 'react';
import {BarcodeDetector} from "barcode-detector";
import localStyles from './styles.module.scss'
import Button from "../Button";
import translate from "../../utils/translate";

interface Interface {
    readerBarCode: (barcode: string) => void;
    close: () => void;
}

const ScannerForm = ({readerBarCode, close}: Interface) => {

    const [cameraOn, setCameraOn] = useState<boolean>(false)
    const [video, setVideo] = useState<any>(null)


    function cropCanvasToImage() {

        const barcodeDetector = new BarcodeDetector({
            formats: ["code_39", "codabar", "ean_13", "code_128"],
        });
        if (video && barcodeDetector) {
            barcodeDetector.detect(video).then(result => {
                if (result && Array.isArray(result) && result.length) {
                    const code = result[0].rawValue;
                    readerBarCode && readerBarCode(code)
                    // alert(code)
                }
            })
        }
    }

    function startCamera(video: any) {
        navigator.mediaDevices.getUserMedia({
            audio: false,
            video: {
                facingMode: 'environment',
                frameRate: {max: 60, min: 30},
                aspectRatio: 3.88
            }
        })
            .then(stream =>  {
                setCameraOn(true)
                return stream
            })
            .then((stream) => {
                video.srcObject = stream;
            })
            .catch((err) => {
                console.error('Помилка доступу до камери: ', err);
            });
    }

    useEffect(() => {

        const idInterval = setInterval(() => {
            if (cameraOn) {
                cropCanvasToImage()
            }
        }, 100)

        return () => {
            clearInterval(idInterval)
        }
    }, [cameraOn]);

    useEffect(() => {
        const video: any = document.getElementById('video');

        setVideo(video)
        startCamera(video)

        return () => {
            setCameraOn(false)
            if (video.srcObject && video.srcObject.getTracks()) {
                video.srcObject.getTracks().map((track: MediaStreamTrack) => track.stop())
            }
        }
    }, []);

    return (
        <div className={localStyles.container}>
            <video id="video" className={localStyles.video} autoPlay></video>
            <Button id={'close-scanner'}
                    background={'bg-danger'}
                    size={'large'}
                    text={translate('cancel')}
                    classes={localStyles.closeScanner}
                    click={() => close()}
            />
        </div>
    );
};

export default ScannerForm;
