import {AxiosResponse} from 'axios';
import axios from '../utils/axios';
import environments from '../utils/environments';
import {string} from "yup";
import {ApiListInterface, ApiOrderInterface, ApiPhotoOrderInterface} from "../interfaces/entity";

export class CoreService {
    private readonly apiUrl: string;

    constructor() {
        this.apiUrl = environments.apiUrl;
    }

    uploadFiles(data: FormData): Promise<AxiosResponse> {
        return axios.post(`${this.apiUrl}/api/v1/files/upload`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    }

    getLinks(): Promise<AxiosResponse> {
        return axios.get(`${this.apiUrl}/api/v1/links`);
    }

    getStatusList(): Promise<AxiosResponse> {
        return axios.get(`${this.apiUrl}/api/v1/files/getStatusList`);
    }

    getOrderList(codeNumber: string): Promise<AxiosResponse<ApiListInterface<ApiOrderInterface>>> {
        return axios.get(`${this.apiUrl}/api/v1/orders`, {
            params: {
                number: codeNumber
            }
        });
    }

    getPhotosByOrder(link: string): Promise<AxiosResponse<ApiPhotoOrderInterface[]>> {
        return axios.get(`${link}?content-type=json`);
    }

}
